/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useRef } from "react"
import { canUseDOM } from "../util"

// https://itnext.io/adding-commento-to-react-apps-like-gatsby-871824fb57ae

function getNormalizedUrl() {
  const url = window.location.origin + window.location.pathname
  return url.replace(/\/$/, "")
}

// Helper to add scripts to our page
const insertScript = (src: string, id: string, parentElement: Node) => {
  const script = window.document.createElement("script")
  script.async = true
  script.src = src
  script.id = id
  parentElement.appendChild(script)
  return script
}
const removeScript = (id: string, parentElement: Node) => {
  const script = window.document.getElementById(id)
  if (script) {
    parentElement.removeChild(script)
  }
}

const remark_config = {
  host: "https://comment.solutions.land", // hostname of remark server, same as REMARK_URL in backend config, e.g. "https://demo.remark42.com"
  site_id: "remark",
  node: "not-loaded",
  components: ["embed"],
  url: "not-loaded",
  max_shown_comments: 10,
  theme: "light",
  locale: "en",
}

if (canUseDOM) {
  // @ts-ignore
  window.remark_config = remark_config
}

export const Remark42: React.FC<{ id: string }> = ({ id }) => {
  const node = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (!canUseDOM) {
      return
    }
    const document = window.document
    remark_config.url = getNormalizedUrl()

    remark_config.node = id
    remark_config.components.forEach(c =>
      insertScript(
        `${remark_config.host}/web/${c}.js`,
        `remark-script-${c}`,
        document.body
      )
    )
    return () => {
      remark_config.components.forEach(c =>
        removeScript(`remark-script-${c}`, document.body)
      )
      if (node.current) {
        node.current.innerHTML = ""
      }
      // @ts-ignore
      if (window.REMARK42) {
        // @ts-ignore
        window.REMARK42.destroy()
      }
    }
  }, [id])
  return <div id={id} ref={node} />
}
