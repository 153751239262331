import { graphql } from "gatsby"
import React from "react"
import {
  PageContainer,
  PageMain,
  PageSidebar,
  PageFooter,
  PageOuterSidebar,
} from "../assemblies/pageTemplate"
import {
  PageProps,
  ArticleContext,
  ArticleSeriesContext,
  ArticleClusterContext,
} from "../types"
import { Stack } from "../layout/stack"
import { PageHeader, PageTitle, PageMeta } from "../assemblies/pageHeader"
import { SubTitle, SubtleTitle, PlainLink } from "../elements/typography"
import { TagGroup } from "../elements/tag"
import { Box } from "../layout/box"
import { NavButton } from "../elements/button"
import { formatDateYMD, expandFrontmatter } from "../util"
import { SecondaryNav } from "../assemblies/nav"
import { SeriesText } from "../elements/motifs"
import { Remark42 } from "../elements/remark42"

const ArticleSeriesFooterNav: React.FC<{ series: ArticleSeriesContext }> = ({
  series: { next, prev },
}) => (
  <Box>
    {prev ? (
      <NavButton to={prev.path} p={-1} style={{ float: "left" }}>
        Previous
      </NavButton>
    ) : null}
    {next ? (
      <NavButton to={next.path} p={-1} style={{ float: "right" }}>
        Next
      </NavButton>
    ) : null}
  </Box>
)
const ArticleClusterNav: React.FC<{ cluster: ArticleClusterContext }> = ({
  cluster: { recent, parent },
}) => (
  <Stack gap={-1}>
    <SubtleTitle>Related {parent.title}:</SubtleTitle>
    {recent.map(({ title, path }) => (
      <PlainLink to={path} key={path}>
        {title}
      </PlainLink>
    ))}
  </Stack>
)

const ArticleTemplate: React.FC<PageProps<
  GatsbyTypes.ArticlePageQuery,
  ArticleContext
>> = ({
  data: { markdownRemark },
  pageContext: { parents, series, cluster },
}) => {
  const { path, title, thumbnail, tags, date } = expandFrontmatter(
    markdownRemark?.frontmatter
  )
  const excerpt = markdownRemark?.excerpt
  const html = markdownRemark?.html || ""
  return (
    <PageContainer>
      <PageOuterSidebar>
        <PageSidebar>
          <SecondaryNav
            parents={parents}
            siblings={series ? series.siblings : [{ title, path }]}
            activePath={path}
          />
        </PageSidebar>
        <PageMain>
          <PageHeader>
            <PageTitle title={title} />
            <PageMeta
              description={excerpt}
              thumbnail={thumbnail?.childImageSharp?.fixed?.src}
            />
            {series ? <SeriesText seriesInfo={series.info} /> : null}
            {tags && tags.length > 0 ? <TagGroup tags={tags} /> : null}
          </PageHeader>
          <SubTitle>{formatDateYMD(new Date(date))}</SubTitle>
          <Stack
            as="article"
            gap={0}
            dangerouslySetInnerHTML={{ __html: html }}
          />
          {series ? <ArticleSeriesFooterNav series={series} /> : null}
          <Remark42 id={`article-${markdownRemark?.id || "unknown"}`} />
          {cluster ? <ArticleClusterNav cluster={cluster} /> : null}
        </PageMain>
      </PageOuterSidebar>
      <PageFooter />
    </PageContainer>
  )
}

export default ArticleTemplate

export const query = graphql`
  query ArticlePage($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      ...MarkdownNode
      html
    }
  }
`
